@import "../../vars.css";

.container{
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 49px;
    z-index: 1;
    margin-left: 10%;
    margin-right: 10%;
   

}
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: var(--color-text);
    z-index: 1;
    

}


.wrap{
    font-size: 4rem;
    font-weight: 900;
    margin-bottom: 33px;
    font-family: var(--font-roboto);
    background: linear-gradient(90deg,rgba(255,255,255,1) 70%, rgba(255,255,255,0) 120%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
    

}
.title{
    font-size: 4rem;
    font-weight: 900;
    margin-bottom: 33px;
    font-family: var(--font-roboto);
    background: linear-gradient(90deg,rgba(255,255,255,1) 70%, rgba(255,255,255,0) 120%);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
   

}
.description{
    font-size: 1.5rem;
    font-family: var(--font-roboto);
    margin-bottom: 52px;


}
.contactBtn{
    text-decoration: none;
    background-color: var(--color-primary);
    color: var(--color-text);
    border-radius: 100px;
    font-size: 30px;
    font-weight: 600;
    padding: 17px 26px;
    box-shadow: 0 0 4px 0 rgba(0,0,0,0.25);
}
.heroImage{
    width: 50%;
    z-index: 1;


    animation-name:floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;

}

@keyframes floating{
    0%{
        transform: translate(0,0px);
    }
    50%{
        transform: translate(0, 10px);
    }
    100%{
        transform: translate(0,-0px);
    }
}
.topBlur{
    position: absolute;
    width: 40vw;
    height: 40vw;
    min-height: 100px;
    min-width: 50px;
    top:-120px;
    left:-5vw;
    border-radius: 764px;
    background: rgba(25,55,109,0.7);
    filter:blur(100px) ;
    z-index: 0;

}
.bottomBlur{
    position: absolute;
    width: 20vw;
    height: 20vw;
    min-height: 100px;
    min-width: 50px;
    top:246px;
    right:-5vw;
    border-radius: 764px;
    background: rgba(25,55,109,0.7);
    filter:blur(100px) ;
    z-index: 0;

}
@media screen and (max-width: 830px){
    .container{
        flex-direction: column-reverse;
    }
    .content{
        align-items: center;
    }
}
