@import "../../vars.css";

.container{
    position: relative;
    background-color: rgba(12,12,12,0.6);
    border-radius: 15px;
    padding:73px;
    margin-top: 129px;
    z-index: 1;
    margin-left: 10%;
    margin-right: 10%;
}
.tech{
    color: #19376d;
}
.title{
    color: var(--color-text);
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 1.75px;
    text-transform: uppercase;
}


.content{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.aboutImg{
    width: 50%;
    z-index: 1;


    animation-name:floating;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

@keyframes floating{
    0%{
        transform: translate(0,0px);
    }
    50%{
        transform: translate(0, -20px);
    }
    100%{
        transform: translate(0,-0px);
    }
}

.aboutItems{
    color: var(--color-text);
    display: flex;
    flex-direction: column;
    gap: 50px;

}

.aboutitem{
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    list-style: none;
    padding: 25px;
    background-image: linear-gradient(90deg, rgba(165,215,232,0.42) 0%, rgba(255,255,255,0) 100%);
    background-size: 0 100%;
    background-repeat: no-repeat;
    transition: 0.4s;
}

.aboutitem:hover{
    background-size: 100% 100%;
    
}

.aboutitemtxt h3{
    font-size: 2rem;
    font-weight:bold;
}

.aboutitem p{
    font-size: 1.2rem;
}

@media screen and (max-width:830px){
    .container{
        background-color: transparent;
        padding-left: 0;
        padding-right: 0;
    }

    .content{
        flex-direction: column;
    }

    .aboutItems{
        margin-top: 29px;
    }

    .aboutitem{
        padding-left: 0;
        padding-right: 0;
    }

    
}