@import "../../vars.css";

.container{
    color: var(--color-text);
    margin-top: 120px;
    background-color: var(--color-secondary);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
    padding: 58px 10%;
    gap: 10px;
    font-family: var(--font-roboto);
    /* background-image: url('../../assets/hero/banner-bg.png');
    background-position: top center;
    background-size: cover;
    background-repeat: no-repeat; */
}

.text h2{
    font-size: 3rem;
    font-weight: 700;
    letter-spacing: 4px;
}

.text p{
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 2px;
}

.links{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
    gap: 26px;
    overflow: hidden;
    
}

.link{
    display: flex;
    align-items: center;
    gap: 25px;
}

.link img{
    width: 40px;
}

.link a{
    color: var(--color-text);
    text-decoration: none;
    font-size: 1.5rem;
    font-weight: 400;
    letter-spacing: 1.5px;
}

@media screen and (max-width:830px){
    .container{
        flex-direction: column;
        gap: 23px;
    }

    .text{
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .links{
        align-items: center;
    }
    .link a{
        
        font-size: 12px;
        
    }
    .link img{
        width: 25px;
    }
}