@import "../../vars.css";

.container{
    
    color: var(--color-text);
    margin-top: 76px;
    margin-left: 10%;
    margin-right: 10%;

}

.title{
   
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 1.75px;
    text-transform: uppercase;
}

.projects{
    
    margin-top: 37px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 100%;
}