@import "../../vars.css";

.container{
    
    color: var(--color-text);
    margin-top: 79px;
    margin-left: 10%;
    margin-right: 0%;
    

}

.title{
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 1.75px;
    text-transform: uppercase;
}

.content{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-top: 14px;
}

.skills{
    width: 45%;
    display: flex;
    flex-wrap: wrap;
    gap:37px;
      
}

.skill{
    display: flex;
    flex-direction: column;
    font-weight: 900;
    align-items: center;
    gap: 11px;
    background: var(--color-dark);
    border-radius: 100%;
    justify-content: center;
    width: 100px;
    height: 100px;
    
}
.skill:hover {
    background-color: var(--color-secondary);
  }

.history{
    width: 45%;
    display: flex;
    flex-direction: column;
    gap:40px;
    margin-right: 10%;
    margin-bottom: 5%;
    margin-top: 5%;
}

.historyItem{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
    /* height: 300px; */
    max-height: 100%;
    background: #19376d;
    background: linear-gradient(180deg, #19376d 0%, rgba(25,55,109,0) 100%);
    border-radius: 10px;
    padding: 24px;
    
}

.nishImg{
    width: 50px;
    border-radius: 100%;
}

.historyItemDetails{
    font-family: "Reddit Mono", monospace;
    
}
.historyItemDetails h3{
    font-size: 30px;
    font-weight: 600;
    font-family: "Reddit Mono", monospace;
    margin: 20px;
}
.historyItemDetails p{
    font-size: 12px;
    font-weight: 100;
    font-family: "Reddit Mono", monospace;
    margin: 20px;
}
.historyItemDetails ul{
    margin-top: 6px;
    list-style-position: inside;
    font-size: 15px;
    list-style-type: disc;
    margin-left: 17px;
    font-family: "Reddit Mono", monospace;
    margin: 20px;
}

@media screen and (max-width:830px){
    .content{
        flex-direction: column;
        align-items: center;
        gap: 34px;
    }
    .skills{
        width: 100%;
        flex-direction: row;
        justify-content: center;
    }
    .history{
        width: 100%;
        gap: 9px;
    }
}