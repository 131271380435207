@import "../../vars.css";

.quesImg{
    width: 120px;
}

.heading{
    color: var(--color-text);
    font-size: 35px;
    font-weight: 700;
    letter-spacing: 1.75px;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 40px;
    margin-top: 50px;

}
.para{
    color: #0b2447;
    font-weight: 900;
    margin: 10px;
    margin-bottom: 20px;
    font-size: 20px;
}