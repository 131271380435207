@import "../../vars.css";

.container{
    display: flex;
    flex-direction: column;
    justify-content:flex-start;
    border-radius: 10px;
    background: linear-gradient(180deg, #576cbc 0%, #132a53 100%);
    box-shadow: 0 16px 14px 0 #04152d;
    padding: 18px 24px;
    max-width: 345px;
    
}

.image{
    margin-bottom: 26px;
}

.title{
    font-size: 25px;
    font-weight: 700;
}

.description{
    margin-top: 6px;
    font-size: 25px;
    font-weight: 400;
}

.skills{
    width: 100%;
    margin-top: 14px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:flex-start;
    gap: 6px;
    list-style: none;
}
 
.skill{
    font-size: 25px;
    font-weight: 400;
    border-radius: 100px;
    background: var(--color-dark);
    padding: 2px 22px;
}

.links{
    width: 100%;
    margin-top: 26px;
    display: flex;
    justify-content: space-around;
}

.link{
    text-decoration: none;
    color: var(--color-text);
    font-size: 30px;
    font-weight: 600;
    border-radius: 100px;
    background: var(--color-primary);
    padding: 1px 22px;
}
